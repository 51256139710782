@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import "~bootstrap/scss/bootstrap";
@import "./sass/variables";

@import "./sass/main";
@import "./sass/navbar";
@import "./sass/header";
@import "./sass/shop";
@import "./sass/blog";
@import "./sass/page";
@import "./sass/faq";
@import "./sass/footer";