html {
  scroll-behavior: auto !important;
  overflow-x: hidden;
}

body {
  font-family: $main-font;
  background-color: $dark-color;
  font-style: normal;
  color: white;
  line-height: normal;
  overflow-x: hidden;
  overscroll-behavior: none;
}

img {
  max-width: 100%;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 0.4em;
}

::-webkit-scrollbar-track {
  background-color: $dark-color;
}

::-webkit-scrollbar-thumb {
  background: $main-color;
}

legend {
  float: unset;
}

a {
  text-decoration: none;
  transition: all 0.1s ease;
  color: $main-color;

  &:hover {
    color: $main-color;
  }
}

.open-post {
  a {
    &:hover {
      text-decoration: underline;
      color: $main-color;
    }
  }
}

img {
  max-width: 100%;
}

h1 {
  font-weight: 700;
  font-size: 30px;

  @media (min-width: 992px) {
    font-size: 60px;
  }
}

input {
  outline: 0 !important;
  box-shadow: none !important;
  border-color: #F0F3F5 !important;
  color: white !important;

  &::placeholder {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5) !important;
  }
}

.input-group-text {
  border: 1px solid #F0F3F5;
  padding: 1rem;

  &.btn {
    border: 0 !important;
    padding: 0;

    svg {
      max-width: 20px;
    }
  }
}

.check {
  .method {
    cursor: pointer;
    background: #ffffff14;
    border: 1px solid #ffffff14;
    padding: 1rem;
    border-radius: 20px;
    width: 100%;
    font-weight: 500;
  }

  input {
    display: none;

    &:checked + label {
      border-color: $main-color;
    }
  }
}

.btn-wallet {
  background-color: $main-color;
  border: 0;
  border-radius: 8px;
  font-weight: 700;
  color: white;
  padding: 0 14px !important;
  transition: all 0.6s ease;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  text-decoration: white;

  &.new-height {
    height: 55px;
    line-height: 55px;
  }

  &.active {
    background-color: white;
    color: $dark-color !important;
  }

  svg {
    margin-right: 10px;
    fill: white;
    width: 30px;
    height: auto;
    max-height: 30px;
  }

  &.account {
    background-color: #272727;
  }
}

.btn-mint {
  border: 1px solid #ffffff14 !important;
  background-color: hsla(0, 0%, 100%, .078) !important;
  color: white !important;
  border-radius: 10px !important;
  width: 100%;
  padding: 1rem !important;
  height: auto !important;
  font-size: 1rem !important;
  transition: all 1s ease-out;
  font-weight: bold !important;
  font-family: $main-font !important;

  &.disabled {
    opacity: 0.5;
  }

  svg {
    height: 20px;
    margin-right: 1rem;
  }

  &:hover {
    background-color: $main-color !important;
    border-color: $main-color !important;
    color: white !important;
  }

  &.sold_out {
    &:hover {
      background-color: hsla(0, 0%, 100%, .078) !important;
      border-color: #ffffff14 !important;
    }
  }
}


.col {
  transition: all 1s ease !important;
}

.lazy-load-image-background.blur {
  filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  filter: blur(0);
  transition: filter .3s;
}

.lazy-load-image-background.blur > img {
  opacity: 0;
  transition: all 0.6s linear !important;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(136, 136, 136, 0.2);

  &:hover {
    transform: perspective(1000px) rotateX(-5deg) rotateY(5deg);
    box-shadow: 0 0 30px rgba(136, 136, 136, 0.4);
  }
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  opacity: 1;
  transition: opacity .3s;
}

.MuiPagination-ul {
  li {
    button, div {
      border-radius: 0;
      background-color: #ffffff1a;
      color: white;
      border-color: white;
      font-weight: 500;
      height: 100%;
      padding: 10px;
      border-radius: 9px;

      &:hover {
        background-color: $main-color !important;
        color: white;
      }

      &.Mui-selected {
        background-color: $main-color;
        color: white;
      }
    }

    .Mui-disabled {
      opacity: 1 !important;
      background-color: #ffffff1a !important;
    }
  }
}