.shop {
  background-color: $shop-bg-color;
  background-image: url("../images/bg-market.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 3rem 0;

  .product {
    background: white;
    border: 1px solid white;
    border-radius: 9px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.5s ease;

    .name {
      color: white;
    }

    .image {
      overflow: hidden;
      min-height: 238px;
      background-color: white;

      img {
        transition: all 0.5s ease;
      }
    }

    &:hover {
      .image {
        img {
          transform: scale(1.1);
        }
      }

      .btn {
        background-color: $main-color;
        color: white;

        &[disabled] {
          opacity: 1;
          color: white;
        }
      }
    }

    img {
      width: 100%;
    }

    .content {
      padding: 1rem;
      border-bottom: 1px solid white;
      background: $dark-second-color;

      .name {
        font-weight: 700;
        margin-bottom: 5px;
      }

      h4 {
        font-weight: 700;
        color: $main-color;
      }

      .price {
        font-weight: 700;
        color: $gray-color;
        margin-bottom: 0;

        .fiat {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    .btn {
      border-radius: 0 0 9px 9px;
      background-color: white;
      padding: 1rem;
      font-weight: 700;
      font-size: 20px;
      transition: all 0.5s ease;
      border: 0 !important;
      color: $dark-color;

      &[disabled] {
        opacity: 1;
        color: $dark-color;
      }
    }
  }

  .btn-scroll {
    border: 0;
    background-color: white;
    border-radius: 36px;
    padding: 1rem;
    font-size: 18px;
    font-weight: 700;
    color: $footer-bg-color;
    transition: all 0.6s ease;

    span {
      vertical-align: middle;

      svg {
        fill: #D32E2E;
        margin-left: 10px;
        transition: all 0.6s ease;
      }
    }

    &:hover {
      background-color: $main-color;
      color: white;

      span {
        svg {
          fill: white;
        }
      }
    }
  }
}

.nft {
  .btn-buy {
    background-color: $main-color;
    border: 0;
    border-radius: 8px;
    font-weight: 700;
    color: white;
    padding: 1rem 4rem !important;
    overflow: hidden;
    transition: all .6s ease-in-out;
    font-size: 20px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    z-index: 1;

    &:after {
      content: "";
      background-color: rgba(255, 255, 255, 0.3);
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transition: none;
      opacity: 0;
      z-index: -1;
    }

    &:hover {
      background-color: white;
      color: $dark-color;

      &:after {
        width: 200%;
        height: 200%;
        opacity: 1;
        transition: all .6s ease-in-out;
      }
    }

    &.sold_out {
      background-color: #131313;
    }
  }

  .image {
    img {
      border-radius: 20px;
    }

    .none {
      img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
    }
  }

  .attribute {
    border: 1px solid $main-color;
    padding: 1rem;
    border-radius: 20px;
    color: white;
    transition: all 0.6s ease;
    cursor: pointer;
    min-height: 102px;

    .name {
      font-weight: 700;
      color: $main-color;
    }

    &:hover {
      border-color: white;
      background-color: white;
      color: $dark-color;
    }
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    color: white;
  }

  .network {
    background: #ffffff17;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    vertical-align: middle;

    svg, span {
      vertical-align: middle;
    }

    svg {
      margin-right: 10px;
    }

    span {
      font-weight: 600;
      font-size: 1rem;
    }
  }

  .tabs {
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn-view {
    background-color: transparent;
    border: 1px solid #ffffff17;
    color: #ffffff61;
    padding: 0.5rem 1rem;
    font-size: 14px;
    border-radius: 20px;
    display: inline-block;
    transition: all 0.5s ease;
    vertical-align: middle;

    &:hover {
      background-color: white;
      color: $dark-color;
    }
  }

  .price {
    font-size: 1.6rem;
  }

  .copy {
    cursor: pointer;

    &:hover {
      color: $main-color;
      transition: all 1s ease;
    }
  }

  hr {
    border-color: #ffffff24;
  }
}