.faq-item {
  cursor: pointer;
  background: rgb(0 0 0 / 70%);
  border: 1px solid rgb(0 0 0 / 70%);
  box-shadow: 0px -5px 10px 0px rgb(0 0 0 / 70%);
  border-radius: 9px;
  padding: 2rem 1rem;
  transition: all 0.6s ease;
  overflow: hidden;

  h5 {
    font-weight: 500;
    font-size: 18px;
  }

  &:hover {
    border-color: white;

    h5 {
      color: white;
    }
  }
}

.shadow-mask {
  position: relative;

  &::after {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    position: absolute;
    background-color: $video-shadow-color;
    width: 100%;
  }
}

.close-icon {
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 30px !important;
  height: 30px !important;
  color: white;
  transition: all 0.6s ease;
  border-radius: 6px;

  &:hover {
    background-color: $main-color;
  }
}

.dialog-container {
  border: 1px solid #fff !important;
}

.dialog-title {
  background-color: black;
  color: white;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: normal !important;
  padding: 2rem !important;
  font-family: $main-font !important;
  border: 0;
}

.dialog-content {
  background-color: black !important;
  padding: 0 2rem 2rem 2rem !important;
}

.dialog-content-text {
  color: white !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: normal !important;
  font-family: $main-font !important;
}

.dialog-content-image {
  .none {
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }

  img {
    border-radius: 20px;
  }
}