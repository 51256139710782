.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 0;
  max-width: inherit;
  box-sizing: border-box;
  transition: all 1s ease;

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &.container {
      padding: 0;
    }

    @media (max-width: 992px) {
      display: block;
      position: relative;
      height: 4rem;
    }

    .logo {
      img {
        max-height: 35px;
      }

      @media (max-width: 992px) {
        position: absolute;
        top: 12px;
        left: 1rem;
        max-height: 40px;
      }
    }

    .menu-items {
      display: flex;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      margin: 0;
      align-items: center;

      @media (max-width: 992px) {
        padding: 0;
        padding-top: 100px !important;
        background: black;
        height: 100vh;
        display: flex;
        flex-direction: column;
        margin: 0;
        transition: transform 0.5s ease-in-out;
        transform: translate(-150%);
      }

      li {
        list-style: none;
        margin-left: 1.5rem;

        @media (max-width: 992px) {
          margin-bottom: 2rem;
          font-size: 1.3rem;
          margin-left: 0;
        }

        a {
          font-weight: 600;
          color: white;

          transition: all 0.6s ease;

          &:hover {
            color: $main-color;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        .btn-logout {
          background-color: $main-color;
          border: 0;
          border-radius: 8px;
          font-weight: 700;
          color: white;
          padding: 13px 14px !important;
          transition: all 0.6s ease;
          margin-left: 1rem;
        }

        .btn-twitter {
          background-color: #272727;
          border-radius: 8px;
          padding: 0 13px !important;
          transition: all 0.6s ease;
          border: 0;
          height: 50px;
          line-height: 50px;

          svg {
            fill: white;
          }

          &:hover {
            background-color: white;

            svg {
              fill: $blue-color;
            }
          }
        }
      }
    }

    .hamburger-lines {
      display: none;
      cursor: pointer;

      @media (max-width: 992px) {
        height: 18px;
        width: 35px;
        position: absolute;
        top: 23px;
        right: 1rem;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .line {
        @media (max-width: 992px) {
          display: block;
          height: 4px;
          width: 100%;
          background: white;
        }
      }

      .line1 {
        @media (max-width: 992px) {
          transform-origin: 9px 0;
          transition: transform 0.4s ease-in-out;
        }
      }

      .line2 {
        @media (max-width: 992px) {
          transform-origin: 0 100%;
          transition: transform 0.4s ease-in-out;
        }
      }
    }

    input[type="checkbox"] {
      display: none;

      @media (max-width: 992px) {
        position: absolute;
        display: block;
        width: 30px;
        top: 20px;
        right: 20px;
        z-index: 5;
        opacity: 0;
      }
    }
  }
}

.header-scroll {
  background: $dark-color;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

  .menu-items {
    padding-top: 0.7rem !important;
    padding-bottom: 0.7rem !important;
  }
}

/* ......../ media query /.......... */

@media (max-width: 992px) {
  .navbar-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(35deg);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: rotate(-35deg);
  }
}

.scroll-off {
  overflow: hidden !important;
}