.page {
  padding-top: 7rem;
  background-color: $shop-bg-color;
  background-image: url("../images/bg-market.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 2rem;
  min-height: 70vh;

  h1 {
    margin-bottom: 2rem;
  }
}