.blog {
  .item {
    cursor: pointer;
    border: 1px solid #F0F3F5;
    border-radius: 9px;
    overflow: hidden;
    text-align: left;
    background-color: #0e0d0bbf;
    transition: all 1s ease-out;

    &:hover {
      border-color: $main-color;
    }

    .content {
      position: relative;
      padding: 2rem;
      color: white !important;

      h3 {
        font-weight: 700;
        font-size: 24px;
      }

      p {
        font-weight: 400;
      }
    }
  }

  .filter {
    .f-categories {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
      box-sizing: border-box;

      li {
        margin: 0 10px 20px 10px;
        list-style: none;
        padding: 1rem 1.46rem;
        color: white;
        border-radius: 6px;
        display: inline-block;
        font-weight: 700;
        cursor: pointer;

        &.active {
          background-color: white;
          color: $dark-color;
        }
      }
    }
  }

  .btn-category, .time {
    vertical-align: middle;
  }

  .btn-category {
    padding: 5px 10px;
    color: white;
    border-radius: 6px;
    display: inline-block;
    font-weight: 700;
    transition: all 1s ease-out;

    &:hover {
      background-color: white;
      color: $dark-color;
    }
  }

  .time {
    margin-left: 15px;
    color: #888;
    font-weight: 500;
  }

  .All {
    background-color: #E2684E;
  }

  .News {
    background-color: #1032CF;
  }

  .Info {
    background-color: #10CFAD;
  }

  .Crypto {
    background-color: #7D33DB;
  }
}

.open-post {
  h1 {
  font-size: 32px;
  }

  strong {
    color: $main-color;
  }

  p {
    line-height: 1.6rem;
  }

  img {
    border-radius: 6px;
  }

  .btn-back {
    font-size: 3rem;
    padding: 5px;
    border-radius: 6px;
    transition: all 1s ease;
    cursor: pointer;
    color: white;

    &:hover {
      background-color: $main-color;
    }
  }

}