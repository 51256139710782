footer {
  position: relative;
  z-index: 2;
  background-color: $footer-bg-color;
  padding: 70px 0;
  color: $footer-color;

  a {
    color: $footer-color;
  }

  h4 {
    font-weight: 700;
    font-size: 20px;
    color: white;
    margin-bottom: 1.5rem;
  }

  .info {
    .footer-logo {
      display: inline-block;
      margin-bottom: 1.5rem;

      img {
        max-height: 35px;
      }
    }

    p {
      font-weight: 500;
    }
  }

  .link {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-weight: 500;
      }
    }
  }

  .contact {
    a {
      font-weight: 500;
    }
  }

  .soc {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      list-style: none;
      margin-right: 1rem;

      svg {
        transition: all 0.6s ease-out;
      }

      &:hover {
        svg {
          transform: rotate(20deg);
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}