.header {
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 100vh;

  .linear-progress {
    position: absolute;
    bottom: 0;
  }

  .categories {
    position: absolute;
    bottom: 4rem;
    padding: 1rem;
    margin: 0;
    transition: all 1s ease;

    li {
      list-style: none;
      border: 1px solid white;
      border-radius: 33px;
      display: inline-block;
      margin-right: 20px;
      padding: 10px 20px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.6s ease;
      margin-bottom: 20px;

      @media (min-width: 992px) {
        margin-bottom: 0;
      }

      &.active {
        background-color: $main-color;
        border-color: $main-color;

        &:hover {
          background-color: $main-color;
          border-color: $main-color;
          color: white;
        }
      }

      &:hover {
        background-color: white;
        border-color: white;
        color: $dark-color;
      }

      &:last-child {
        margin: 0;
      }
    }

    &.filter-fixed {
      @media (min-width: 992px) {
        position: fixed;

        background-color: $dark-color;
        bottom: 1rem;
        border-radius: 33px;
        z-index: 1;
      }
    }
  }

  video {
    object-fit: cover;
    object-position: bottom;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
}